export class Collection<T> {
  data: T[]
  totalCount: number
  page: number
  perPage: number

  constructor(response: any, t: new (dataResponse: any) => T) {
    this.totalCount = response.totalCount
    this.page = response.page
    this.perPage = response.perPage

    let data: [] = response.data
    this.data = []
    data.forEach(item => {
      this.data.push(new t(item))
    })
  }
}
